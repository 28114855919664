<!--
 * @Description: 添加街道弹框
 * @Author: ChenXueLin
 * @Date: 2021-09-10 15:20:48
 * @LastEditTime: 2023-02-15 11:26:59
 * @LastEditors: ChenXueLin
-->
<template>
  <el-dialog
    v-dialogDrag
    :title="addStreetType == 'add' ? '添加街道' : '编辑街道'"
    :visible.sync="addStreetVisible"
    width="600px"
    :before-close="handleClose"
    :close-on-click-modal="false"
    v-loading="dialogLoading"
    :element-loading-background="loadingBackground"
    custom-class="custom-dialog"
  >
    <el-form
      ref="streetForm"
      label-width="120px"
      :model="streetForm"
      :rules="streetFormRules"
    >
      <el-form-item label="大区名称" prop="regionalId">
        <e6-vr-select
          v-model="streetForm.regionalId"
          :data="regionalList"
          placeholder="大区名称"
          title="大区名称"
          :props="{
            id: 'areaId',
            label: 'areaName'
          }"
        ></e6-vr-select>
      </el-form-item>
      <el-form-item label="片区名称" prop="districtId">
        <e6-vr-select
          v-model="streetForm.districtId"
          :data="areaList"
          placeholder="片区名称"
          title="片区名称"
          :props="{
            id: 'areaId',
            label: 'areaName'
          }"
        ></e6-vr-select>
      </el-form-item>
      <el-form-item label="城市名称" prop="selCityId">
        <e6-vr-select
          v-model="streetForm.selCityId"
          :data="cityList"
          placeholder="城市名称"
          title="城市名称"
          clearable
          :props="{
            id: 'areaId',
            label: 'areaName'
          }"
          @change="handleSelCity"
        ></e6-vr-select>
      </el-form-item>
      <el-form-item label="街道名称" prop="streetId">
        <e6-vr-select
          v-model="streetForm.streetId"
          :data="streetList"
          placeholder="街道名称"
          title="街道名称"
          clearable
          :props="{
            id: 'code',
            label: 'name'
          }"
        ></e6-vr-select>
      </el-form-item>
    </el-form>
    <div class="dialog-footer" slot="footer">
      <el-button class="cancel" @click="handleClose">取消</el-button>
      <el-button type="primary" @click="confirm">确定</el-button>
    </div>
  </el-dialog>
</template>

<script>
import base from "@/mixins/base";
import { printError } from "@/utils/util";
import {
  getAreaDown,
  getAreaDetail,
  getStreetList,
  createStreet,
  updateStreet
} from "@/api";
export default {
  name: "addStreet",
  components: {},
  data() {
    return {
      dialogLoading: false,
      regionalList: [], //大区
      areaList: [], //片区
      cityList: [],
      streetList: [],
      streetForm: {
        regionalId: "", //大区id
        districtId: "", //片区id
        areaName: "", //片区名称
        selCityId: "", //城市
        streetId: "",
        streetName: "", //街道
        provinceId: "",
        countyId: "",
        cityName: "",
        provinceName: "",
        countyName: ""
      },
      streetFormRules: {
        regionalId: [
          {
            required: true,
            message: "请选择大区名称",
            trigger: ["blur", "change"]
          }
        ],
        districtId: [
          {
            required: true,
            message: "请选择片区名称",
            trigger: ["blur", "change"]
          }
        ],
        selCityId: [
          {
            required: true,
            message: "请选择城市名称",
            trigger: ["blur", "change"]
          }
        ],
        streetId: [
          {
            required: true,
            message: "请选择街道名称",
            trigger: ["blur", "change"]
          }
        ]
      }
    };
  },
  props: ["addStreetVisible", "addStreetType", "areaId"],
  mixins: [base],
  computed: {},
  watch: {
    addStreetVisible: {
      immediate: true,
      handler(val) {
        if (val) {
          this.getAreaDown();
          if (this.addStreetType == "edit") {
            this.getAreaDetail();
          }
        }
      }
    },
    //监控大区
    "streetForm.regionalId": {
      immediate: true,
      handler(val, oldVal) {
        if (val) {
          this.getAreaList();
          if (oldVal && val != oldVal) {
            this.streetForm.districtId = "";
            this.streetForm.selCityId = "";
            this.streetForm.streetId = "";
            this.areaList = [];
            this.cityList = [];
            this.streetList = [];
          }
        }
        if (!val) {
          this.streetForm.districtId = "";
          this.streetForm.selCityId = "";
          this.streetForm.streetId = "";
          this.areaList = [];
          this.cityList = [];
          this.streetList = [];
        }
      }
    },
    //监控片区
    "streetForm.districtId": {
      immediate: true,
      handler(val, oldVal) {
        if (val) {
          this.getCityList();
          if (oldVal && val != oldVal) {
            this.streetForm.selCityId = "";
            this.cityList = [];
          }
        }
        if (!val) {
          this.streetForm.selCityId = "";
          this.cityList = [];
        }
      }
    },
    //监控城市
    "streetForm.selCityId": {
      immediate: true,
      handler(val, oldVal) {
        if (val) {
          // if (this.addStreetType == "add") {
          //   let cityInfo = this.cityList.find(item => {
          //     return item.areaId == val;
          //   });
          //   this.streetForm.cityId =
          //     cityInfo && cityInfo.cityId ? cityInfo.cityId : "";
          //   this.streetForm.countyId =
          //     cityInfo && cityInfo.countyId ? cityInfo.countyId : "";
          // }
          // this.getStreetList();
          if (this.addStreetType != "add") {
            this.getStreetList();
          }
          if (oldVal && val != oldVal) {
            this.streetForm.streetId = "";
            this.streetList = [];
          }
        }
        if (!val) {
          this.streetForm.streetId = "";
          this.streetList = [];
        }
      }
    }
  },
  methods: {
    //获取大区和片区列表
    async getAreaDown() {
      try {
        let res = await getAreaDown({ parentId: "" });
        //大区下拉框
        this.regionalList = _.cloneDeep(res.data);
      } catch (error) {
        printError(error);
      } finally {
        this.loading = false;
      }
    },
    //获取片区列表
    async getAreaList() {
      try {
        let res = await getAreaDown({ parentId: this.streetForm.regionalId });
        //片区
        this.areaList = _.cloneDeep(res.data);
      } catch (error) {
        printError(error);
      } finally {
        this.loading = false;
      }
    },
    //获取城市列表
    async getCityList() {
      try {
        let res = await getAreaDown({ parentId: this.streetForm.districtId });
        //城市
        this.cityList = _.cloneDeep(res.data).filter(item => {
          return item.countyId || item.cityId;
        });
      } catch (error) {
        printError(error);
      } finally {
        this.loading = false;
      }
    },
    //获取街道下拉框
    async getStreetList() {
      try {
        this.cityLoading = true;
        let res = await getStreetList({
          code: this.streetForm.countyId || this.streetForm.cityId
        });
        this.streetList = res.data;
      } catch (error) {
        printError(error);
      } finally {
        this.cityLoading = false;
      }
    },
    //获取大区详情
    async getAreaDetail() {
      try {
        this.dialogLoading = true;
        let res = await getAreaDetail({ areaId: this.areaId });
        let streetForm = _.cloneDeep(res.data);
        this.streetForm = {
          areaId: streetForm.areaId, //编辑id
          versionNumber: streetForm.versionNumber,
          regionalId: streetForm.regionalAreaId, //大区id
          districtId: streetForm.sectionAreaId, //片区id
          selCityId: streetForm.cityAdminAreaId, //城市id。相当于添加的parentId
          countyId: streetForm.countyId, //区code
          cityId: streetForm.cityId, //城市code
          parentId: streetForm.cityAdminAreaId,
          streetId: streetForm.streetId ? streetForm.streetId.toString() : "" //街道id
        };
      } catch (error) {
        printError(error);
      } finally {
        this.dialogLoading = false;
      }
    },
    //选择城市
    handleSelCity(a, node) {
      let cityInfo = this.cityList.find(item => {
        return item.areaId == node.areaId;
      });
      this.streetForm.cityId =
        cityInfo && cityInfo.cityId ? cityInfo.cityId : "";
      this.streetForm.countyId =
        cityInfo && cityInfo.countyId ? cityInfo.countyId : "";
      this.getStreetList();
    },
    confirm() {
      this.$refs.streetForm.validate(valid => {
        if (valid) {
          if (this.addStreetType == "add") {
            this.createStreet();
          } else {
            this.updateStreet();
          }
        }
      });
    },
    //创建街道请求
    async createStreet() {
      try {
        this.dialogLoading = true;
        let addressInfo = this.cityList.find(item => {
          return item.areaId == this.streetForm.selCityId;
        });
        let streetName = this.streetList.find(item => {
          return item.code == this.streetForm.streetId;
        }).name;
        let areaName = this.areaList.find(item => {
          return item.areaId == this.streetForm.districtId;
        }).areaName;
        let res = await createStreet({
          streetId: this.streetForm.streetId,
          parentId: addressInfo.areaId,
          areaId: this.streetForm.streetId,
          cityId: addressInfo.cityId,
          areaName,
          countyId: addressInfo.countyId,
          provinceId: addressInfo.provinceId,
          provinceName: addressInfo.provinceName,
          cityName: addressInfo.cityName,
          countyName: addressInfo.countyName,
          streetName
        });
        if (res.code == "OK") {
          this.$message.success("创建成功");
          this.$emit("refresh");
          this.handleClose();
        }
      } catch (error) {
        printError(error);
      } finally {
        this.dialogLoading = false;
      }
    },
    //编辑城市请求
    async updateStreet() {
      try {
        this.dialogLoading = true;
        let addressInfo = this.cityList.find(item => {
          return item.areaId == this.streetForm.selCityId;
        });
        let streetInfo = this.streetList.find(item => {
          return item.code == this.streetForm.streetId;
        });
        let areaName = this.areaList.find(item => {
          return item.areaId == this.streetForm.districtId;
        }).areaName;
        let res = await updateStreet({
          streetId: streetInfo.code,
          streetName: streetInfo.name,
          areaName,
          areaId: this.streetForm.areaId,
          cityId: this.streetForm.cityId,
          parentId: addressInfo.areaId,
          countyId: this.streetForm.countyId,
          provinceId: addressInfo.provinceId,
          versionNumber: this.streetForm.versionNumber,
          provinceName: addressInfo.provinceName,
          cityName: addressInfo.cityName,
          countyName: addressInfo.countyName
        });
        if (res.code == "OK") {
          this.$message.success("编辑成功");
          this.$emit("refresh");
          this.handleClose();
        }
      } catch (error) {
        printError(error);
      } finally {
        this.dialogLoading = false;
      }
    },
    handleClose() {
      this.streetForm = {
        regionalId: "", //大区id
        districtId: "", //片区id
        selCityId: "", //城市
        areaName: "", //街道id
        streetId: "",
        areaId: "",
        versionNumber: "",
        streetName: "", //街道
        provinceId: "",
        countyId: ""
      };
      this.$refs.streetForm.resetFields();
      this.$emit("handleClose", "addStreetVisible");
    }
  },
  created() {}
};
</script>
<style lang="scss" scoped>
/deep/.custom-dialog {
  .el-dialog__body {
    padding: 20px 18px 2px 18px;
    .el-input {
      width: 280px;
    }
  }
}
</style>

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('el-dialog',{directives:[{name:"dialogDrag",rawName:"v-dialogDrag"},{name:"loading",rawName:"v-loading",value:(_vm.dialogLoading),expression:"dialogLoading"}],attrs:{"title":_vm.addAreaType == 'add' ? '添加片区' : '编辑片区',"visible":_vm.addAreaVisible,"width":"500px","before-close":_vm.handleClose,"close-on-click-modal":false,"element-loading-background":_vm.loadingBackground,"custom-class":"custom-dialog"},on:{"update:visible":function($event){_vm.addAreaVisible=$event}}},[_c('el-form',{ref:"areaForm",attrs:{"label-width":"88px","model":_vm.areaForm,"rules":_vm.areaFormRules}},[_c('el-form-item',{attrs:{"label":"片区名称","prop":"areaName"}},[_c('el-input',{attrs:{"placeholder":"请输入片区名称"},model:{value:(_vm.areaForm.areaName),callback:function ($$v) {_vm.$set(_vm.areaForm, "areaName", $$v)},expression:"areaForm.areaName"}})],1),_vm._v(" "),_c('el-form-item',{attrs:{"label":"大区名称","prop":"parentId"}},[_c('e6-vr-select',{attrs:{"data":_vm.areaList,"placeholder":"大区名称","title":"大区名称","clearable":"","props":{
          id: 'areaId',
          label: 'areaName'
        }},model:{value:(_vm.areaForm.parentId),callback:function ($$v) {_vm.$set(_vm.areaForm, "parentId", $$v)},expression:"areaForm.parentId"}})],1),_vm._v(" "),_c('el-form-item',{staticClass:"workEmployee",attrs:{"label":"工程负责人"}}),_vm._v(" "),_vm._l((_vm.list),function(item,index){return _c('div',{key:index,staticClass:"content"},[_c('div',{staticStyle:{"margin-left":"16px","margin-bottom":"10px"}},[_c('e6-vr-select',{attrs:{"data":_vm.employeeList,"placeholder":"工程负责人","title":"工程负责人","clearable":"","props":{
            id: 'employeeId',
            label: 'employeeName'
          }},model:{value:(item.workEmployeeId),callback:function ($$v) {_vm.$set(item, "workEmployeeId", $$v)},expression:"item.workEmployeeId"}}),_vm._v(" "),_c('i',{staticClass:"e6-icon-add_fill",on:{"click":_vm.addRow}}),_vm._v(" "),(_vm.list.length > 1)?_c('i',{staticClass:"e6-icon-minus_fill",on:{"click":function($event){return _vm.deleteRow(index)}}}):_vm._e()],1),_vm._v(" "),_c('el-form-item',{staticStyle:{"margin-bottom":"10px"},attrs:{"label":"负责主体"}},[_c('el-checkbox-group',{on:{"change":_vm.changeCheckbox},model:{value:(item.companyNos),callback:function ($$v) {_vm.$set(item, "companyNos", $$v)},expression:"item.companyNos"}},_vm._l((item.WorkCompanyNoList),function(each){return _c('el-checkbox',{key:each.codeId,attrs:{"label":each.codeValue,"disabled":each.disabled}},[_vm._v(_vm._s(each.codeName))])}),1)],1)],1)}),_vm._v(" "),_c('div',{staticClass:"tip"},[_vm._v("\n      说明：存在多个工程师负责人时，负责主体不能交叉，已选主体不能被其他工程负责人选择，需要选择请去掉勾选。\n    ")]),_vm._v(" "),_c('el-form-item',{attrs:{"label":"调度负责人","prop":"scheduleEmployeeId"}},[_c('e6-vr-select',{attrs:{"data":_vm.scheduleEmployeeList,"placeholder":"调度负责人","title":"调度负责人","clearable":"","props":{
          id: 'employeeId',
          label: 'employeeName'
        }},model:{value:(_vm.areaForm.scheduleEmployeeId),callback:function ($$v) {_vm.$set(_vm.areaForm, "scheduleEmployeeId", $$v)},expression:"areaForm.scheduleEmployeeId"}})],1)],2),_vm._v(" "),_c('div',{staticClass:"dialog-footer",attrs:{"slot":"footer"},slot:"footer"},[_c('el-button',{staticClass:"cancel",on:{"click":_vm.handleClose}},[_vm._v("取消")]),_vm._v(" "),_c('el-button',{attrs:{"type":"primary"},on:{"click":_vm.confirm}},[_vm._v("确定")])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }
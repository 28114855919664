<!--
 * @Description: 地区管理
 * @Author: ChenXueLin
 * @Date: 2021-09-10 15:11:27
 * @LastEditTime: 2022-08-02 15:42:48
 * @LastEditors: ChenXueLin
-->
<template>
  <div class="content-view-wrapper pd-10">
    <div
      class="content-view section-wrapper"
      ref="sectionWrapper"
      v-loading="loading"
      :element-loading-background="loadingBackground"
    >
      <!-- 搜索头部 start -->
      <table-title @resize="handleTableTitleResize">
        <template v-slot:searchForm>
          <el-form class="search-list" ref="searchForm" :model="searchForm">
            <el-form-item class="search-item--1" prop="regionalId">
              <e6-vr-select
                v-model="searchForm.regionalId"
                :data="regionalList"
                placeholder="大区名称"
                title="大区名称"
                clearable
                :props="{
                  id: 'areaId',
                  label: 'areaName'
                }"
              ></e6-vr-select>
            </el-form-item>
            <el-form-item class="search-item--1" prop="areaId">
              <e6-vr-select
                v-model="searchForm.areaId"
                :data="areaList"
                placeholder="片区名称"
                title="片区名称"
                clearable
                :props="{
                  id: 'areaId',
                  label: 'areaName'
                }"
              ></e6-vr-select>
            </el-form-item>
            <el-form-item class="search-item--buttons">
              <el-button type="primary" @click="handleSearch">查询</el-button>
              <el-button class="reset" @click="handleReset">重置</el-button>
            </el-form-item>
          </el-form>
        </template>
        <template v-slot:operateAction>
          <el-button type="primary" @click="handleAddRegional(1)"
            >添加大区</el-button
          >
          <el-button type="primary" @click="handleAddRegional(2)"
            >添加片区</el-button
          >
          <el-button type="primary" @click="handleAddRegional(3)"
            >添加城市/行政区</el-button
          >
          <el-button type="primary" @click="handleAddRegional(4)"
            >添加街道</el-button
          >
          <el-button type="primary" @click="handleEdit">编辑</el-button>
          <el-button
            type="primary"
            style="margin-right:10px;"
            @click="handleMove"
            >区域转移</el-button
          >
        </template>
      </table-title>
      <!-- 搜索头部 end -->

      <!-- 表格 start -->
      <section class="table-wrapper">
        <div class="treeData" :style="'height:' + resizeViewHeight + 'px'">
          <e6-tree
            :list="treeData"
            ref="e6Tree"
            :is-open="true"
            :disabledNoCheck="true"
            @func="handleTreeClick"
          ></e6-tree>
        </div>
        <div class="table-box">
          <el-table
            border
            :height="resizeViewHeight + 'px'"
            :data="tableData"
            highlight-current-row
            ref="elTable"
            @selection-change="handleSelectChange"
          >
            <el-table-column type="selection" width="40"></el-table-column>
            <el-table-column
              show-overflow-tooltip
              v-for="(column, index) in columnData"
              :key="index"
              :prop="column.fieldName"
              :label="column.fieldLabel"
              :min-width="column.width"
              :fixed="column.fixed"
              :align="column.align"
              header-align="center"
            >
            </el-table-column>
            <el-table-column
              fixed="right"
              align="center"
              label="操作"
              width="120"
            >
              <template slot-scope="scope">
                <e6-td-operate
                  :data="getOperateList()"
                  @command="handleOperate($event, scope.row)"
                ></e6-td-operate>
              </template>
            </el-table-column>
          </el-table>
        </div>
      </section>
      <!-- 表格 end -->

      <!-- 分页 start -->
      <section class="pagination-wrapper fixed-section">
        <el-pagination
          :page-size.sync="searchForm.pageSize"
          :current-page.sync="searchForm.pageIndex"
          :page-sizes="pageSizes"
          :layout="layout"
          :total="total"
          background
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
        ></el-pagination>
      </section>
      <!-- 分页 end -->
    </div>
    <!-- 添加/编辑大区 -->
    <add-regional
      :addRegionalVisible="addRegionalVisible"
      @refresh="refreshData"
      @handleClose="handleClose"
      :addRegionalType="addRegionalType"
      :areaId="curNode.areaId || curNode.regionalId"
    ></add-regional>
    <!-- 添加/编辑片区 -->
    <add-area
      :addAreaVisible="addAreaVisible"
      @refresh="refreshData"
      @handleClose="handleClose"
      :addAreaType="addAreaType"
      :areaId="curNode.areaId"
    ></add-area>
    <!-- 添加城市 -->
    <add-city
      :addCityType="addCityType"
      :areaId="rowId"
      :addCityVisible="addCityVisible"
      @refresh="refreshData"
      @handleClose="handleClose"
    ></add-city>
    <!-- 添加街道 -->
    <add-street
      :addStreetType="addStreetType"
      :areaId="rowId"
      :addStreetVisible="addStreetVisible"
      @refresh="refreshData"
      @handleClose="handleClose"
    ></add-street>
    <!-- 区域转移 -->
    <move-area
      :moveAreaVisible="moveAreaVisible"
      @refresh="refreshData"
      @handleClose="handleClose"
      :areaIdList="areaIdList"
    ></move-area>
  </div>
</template>

<script>
import base from "@/mixins/base";
import listPage from "@/mixins/list-page";
import listPageReszie from "@/mixins/list-page-resize";
import addRegional from "./components/addRegional";
import { getListRegionalAndArea, getAreaAddListPage, getAreaDown } from "@/api";
import { printError } from "@/utils/util";
import AddArea from "./components/addArea.vue";
import AddCity from "./components/addCity.vue";
import AddStreet from "./components/addStreet.vue";
import moveArea from "./components/moveArea.vue";
export default {
  name: "areaList",
  components: {
    addRegional,
    AddArea,
    AddCity,
    AddStreet, //添加片区弹框
    moveArea
  },
  data() {
    return {
      total: 0,
      regionalList: [], //大区名称
      areaList: [], //片区名称
      searchForm: {
        regionalId: "", //大区id
        areaId: "", //片区id
        pageIndex: 1,
        pageSize: 20
      },
      treeData: [],
      columnData: [
        {
          fieldName: "provinceName",
          display: true,
          fieldLabel: "省份",
          width: 160,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "cityName",
          display: true,
          fieldLabel: "城市",
          width: 160,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "countyName",
          display: true,
          fieldLabel: "行政区",
          width: 160,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "streetName",
          display: true,
          fieldLabel: "街道",
          width: 160,
          disabled: false,
          fixed: false,
          align: "center"
        }
      ], //表头
      tableData: [],
      addRegionalVisible: false,
      addRegionalType: "add",
      addAreaVisible: false,
      addAreaType: "add",
      curNode: {}, //当前点击的节点
      addCityVisible: false,
      addCityType: "add",
      addStreetType: "add",
      addStreetVisible: false,
      rowId: "",
      refTable: "elTable",
      queryListAPI: getAreaAddListPage,
      moveAreaVisible: false,
      areaIdList: []
    };
  },
  mixins: [base, listPage, listPageReszie],
  computed: {},
  watch: {
    "searchForm.regionalId": {
      immediate: true,
      handler(val) {
        if (val) {
          this.getAreaDown();
        } else {
          this.searchForm.areaId = "";
          this.areaList = [];
        }
      }
    }
  },
  created() {
    this.getAreaDown();
    this.getListRegionalAndArea();
  },
  mounted() {
    this.setTableScrollDOM("elTable");
  },
  activated() {
    this.setScrollRecord();
  },
  methods: {
    //修改后刷新列表
    refreshData() {
      // this.searchForm = {
      //   regionalId: "", //大区id
      //   areaId: "", //片区id
      //   pageIndex: 1,
      //   pageSize: 20
      // };
      this.getAreaDown();
      this.getListRegionalAndArea(1);
    },
    //获取大区/片区下拉框
    async getAreaDown() {
      let res = await getAreaDown({
        parentId: this.searchForm.regionalId
      });
      if (this.searchForm.regionalId) {
        this.areaList = res.data;
      } else {
        this.regionalList = res.data;
      }
    },
    //获取大区树
    async getListRegionalAndArea(type) {
      try {
        this.loading = true;
        let res = await getListRegionalAndArea({});
        let data = res.data;
        data.map(item => {
          item.pid = item.parentId ? item.parentId : 0;
          item.label = item.areaName;
          item.id = item.areaId;
          item.children =
            item.areaList && item.areaList.length ? item.areaList : [];
          if (item.areaList && item.areaList.length) {
            item.areaList.map(each => {
              each.pid = each.parentId;
              each.label = each.areaName;
              each.id = each.areaId;
            });
          }
        });
        this.treeData = data;
        if (type) {
          setTimeout(() => {
            this.handleSearch();
          }, 300);
        } else {
          this.queryList();
        }
      } catch (error) {
        printError(error);
      } finally {
        this.loading = false;
      }
    },
    //点击查询
    handleSearch() {
      this.$refs.e6Tree.setAllNodeSelect(false);
      if (this.searchForm.areaId) {
        this.$refs.e6Tree.setNodeSelect({ id: this.searchForm.areaId }, true);
        this.curNode.areaType = 2;
        this.curNode.regionalId = this.searchForm.regionalId;
        this.curNode.areaId = this.searchForm.areaId;
      } else {
        if (this.searchForm.regionalId) {
          this.$refs.e6Tree.setNodeSelect(
            { id: this.searchForm.regionalId },
            true
          );
          this.curNode.areaType = 1;
          this.curNode.regionalId = this.searchForm.regionalId;
        }
      }
      this.searchForm.pageIndex = 1;
      this.queryList();
    },
    //多选
    handleSelectChange(val) {
      this.areaIdList = val.map(item => item.areaId);
    },
    // 获取页面操作权限
    getOperateList() {
      // 页面按钮权限
      let operates = [
        {
          label: "编辑",
          id: 1
        }
      ];
      return operates;
    },
    // 操作--更多
    handleOperate(val, row) {
      if (val.id == 1) {
        if (row.areaType == 3) {
          //城市
          this.addCityVisible = true;
          this.addCityType = "edit";
          this.rowId = row.areaId;
        }
        if (row.areaType == 4) {
          //街道
          this.addStreetVisible = true;
          this.addStreetType = "edit";
          this.rowId = row.areaId;
        }
      }
    },
    //添加大区、片区、城市
    handleAddRegional(dialogType) {
      switch (dialogType) {
        case 1:
          this.addRegionalType = "add";
          this.addRegionalVisible = true;
          break;
        case 2:
          this.addAreaType = "add";
          this.addAreaVisible = true;
          break;
        case 3:
          this.addCityType = "add";
          this.addCityVisible = true;
          break;
        case 4:
          this.addStreetType = "add";
          this.addStreetVisible = true;
          break;
      }
    },
    // 处理树节点点击
    handleTreeClick(node) {
      this.curNode = node;
      if (node.areaType == 1) {
        this.searchForm.regionalId = node.areaId;
        this.searchForm.areaId = "";
      }
      if (node.areaType == 2) {
        this.searchForm.regionalId = node.parentId;
        this.searchForm.areaId = node.areaId;
      }
      this.queryList();
    },
    //点击编辑
    handleEdit() {
      if (!this.curNode.areaType) {
        this.$message.warning("请选择大区或片区进行编辑");
        return;
      }
      if (this.curNode.areaType == 1) {
        //大区
        this.addRegionalVisible = true;
        this.addRegionalType = "edit";
      }
      if (this.curNode.areaType == 2) {
        //片区
        this.addAreaVisible = true;
        this.addAreaType = "edit";
      }
    },
    //区域转移
    handleMove() {
      if (!this.areaIdList.length) {
        this.$message.warning("请选择要选中的区域");
        return;
      }
      this.moveAreaVisible = true;
    },
    //关闭弹框
    handleClose(dialogName) {
      this[dialogName] = false;
      if (dialogName == "moveAreaVisible") {
        this.$refs.elTable.clearSelection();
      }
    }
  }
};
</script>
<style lang="scss" scoped>
.table-wrapper {
  display: flex;
  .treeData {
    width: 340px;
    height: 100%;
    box-sizing: border-box;
    padding: 10px 0 0 10px;
  }
  .table-box {
    width: calc(100% - 339px);
    height: 100%;
    border-left: 1px solid #edf0f5;
  }
}
</style>

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('el-dialog',{directives:[{name:"dialogDrag",rawName:"v-dialogDrag"},{name:"loading",rawName:"v-loading",value:(_vm.dialogLoading),expression:"dialogLoading"}],attrs:{"title":_vm.addStreetType == 'add' ? '添加街道' : '编辑街道',"visible":_vm.addStreetVisible,"width":"600px","before-close":_vm.handleClose,"close-on-click-modal":false,"element-loading-background":_vm.loadingBackground,"custom-class":"custom-dialog"},on:{"update:visible":function($event){_vm.addStreetVisible=$event}}},[_c('el-form',{ref:"streetForm",attrs:{"label-width":"120px","model":_vm.streetForm,"rules":_vm.streetFormRules}},[_c('el-form-item',{attrs:{"label":"大区名称","prop":"regionalId"}},[_c('e6-vr-select',{attrs:{"data":_vm.regionalList,"placeholder":"大区名称","title":"大区名称","props":{
          id: 'areaId',
          label: 'areaName'
        }},model:{value:(_vm.streetForm.regionalId),callback:function ($$v) {_vm.$set(_vm.streetForm, "regionalId", $$v)},expression:"streetForm.regionalId"}})],1),_vm._v(" "),_c('el-form-item',{attrs:{"label":"片区名称","prop":"districtId"}},[_c('e6-vr-select',{attrs:{"data":_vm.areaList,"placeholder":"片区名称","title":"片区名称","props":{
          id: 'areaId',
          label: 'areaName'
        }},model:{value:(_vm.streetForm.districtId),callback:function ($$v) {_vm.$set(_vm.streetForm, "districtId", $$v)},expression:"streetForm.districtId"}})],1),_vm._v(" "),_c('el-form-item',{attrs:{"label":"城市名称","prop":"selCityId"}},[_c('e6-vr-select',{attrs:{"data":_vm.cityList,"placeholder":"城市名称","title":"城市名称","clearable":"","props":{
          id: 'areaId',
          label: 'areaName'
        }},on:{"change":_vm.handleSelCity},model:{value:(_vm.streetForm.selCityId),callback:function ($$v) {_vm.$set(_vm.streetForm, "selCityId", $$v)},expression:"streetForm.selCityId"}})],1),_vm._v(" "),_c('el-form-item',{attrs:{"label":"街道名称","prop":"streetId"}},[_c('e6-vr-select',{attrs:{"data":_vm.streetList,"placeholder":"街道名称","title":"街道名称","clearable":"","props":{
          id: 'code',
          label: 'name'
        }},model:{value:(_vm.streetForm.streetId),callback:function ($$v) {_vm.$set(_vm.streetForm, "streetId", $$v)},expression:"streetForm.streetId"}})],1)],1),_vm._v(" "),_c('div',{staticClass:"dialog-footer",attrs:{"slot":"footer"},slot:"footer"},[_c('el-button',{staticClass:"cancel",on:{"click":_vm.handleClose}},[_vm._v("取消")]),_vm._v(" "),_c('el-button',{attrs:{"type":"primary"},on:{"click":_vm.confirm}},[_vm._v("确定")])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }
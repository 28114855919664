<!--
 * @Description: 添加片区弹框
 * @Author: ChenXueLin
 * @Date: 2021-09-10 15:20:48
 * @LastEditTime: 2023-03-07 13:44:21
 * @LastEditors: ChenXueLin
-->
<template>
  <el-dialog
    v-dialogDrag
    :title="addAreaType == 'add' ? '添加片区' : '编辑片区'"
    :visible.sync="addAreaVisible"
    width="500px"
    :before-close="handleClose"
    :close-on-click-modal="false"
    v-loading="dialogLoading"
    :element-loading-background="loadingBackground"
    custom-class="custom-dialog"
  >
    <el-form
      ref="areaForm"
      label-width="88px"
      :model="areaForm"
      :rules="areaFormRules"
    >
      <el-form-item label="片区名称" prop="areaName">
        <el-input placeholder="请输入片区名称" v-model="areaForm.areaName">
        </el-input>
      </el-form-item>
      <el-form-item label="大区名称" prop="parentId">
        <e6-vr-select
          v-model="areaForm.parentId"
          :data="areaList"
          placeholder="大区名称"
          title="大区名称"
          clearable
          :props="{
            id: 'areaId',
            label: 'areaName'
          }"
        ></e6-vr-select>
      </el-form-item>
      <el-form-item label="工程负责人" class="workEmployee"> </el-form-item>
      <div class="content" v-for="(item, index) in list" :key="index">
        <div style="margin-left:16px;margin-bottom:10px;">
          <e6-vr-select
            v-model="item.workEmployeeId"
            :data="employeeList"
            placeholder="工程负责人"
            title="工程负责人"
            clearable
            :props="{
              id: 'employeeId',
              label: 'employeeName'
            }"
          ></e6-vr-select>
          <i class="e6-icon-add_fill" @click="addRow"></i>
          <i
            class="e6-icon-minus_fill"
            v-if="list.length > 1"
            @click="deleteRow(index)"
          ></i>
        </div>
        <el-form-item label="负责主体" style="margin-bottom:10px;">
          <el-checkbox-group v-model="item.companyNos" @change="changeCheckbox">
            <el-checkbox
              :label="each.codeValue"
              v-for="each in item.WorkCompanyNoList"
              :key="each.codeId"
              :disabled="each.disabled"
              >{{ each.codeName }}</el-checkbox
            >
          </el-checkbox-group>
        </el-form-item>
      </div>
      <div class="tip">
        说明：存在多个工程师负责人时，负责主体不能交叉，已选主体不能被其他工程负责人选择，需要选择请去掉勾选。
      </div>
      <el-form-item label="调度负责人" prop="scheduleEmployeeId">
        <e6-vr-select
          v-model="areaForm.scheduleEmployeeId"
          :data="scheduleEmployeeList"
          placeholder="调度负责人"
          title="调度负责人"
          clearable
          :props="{
            id: 'employeeId',
            label: 'employeeName'
          }"
        ></e6-vr-select>
      </el-form-item>
    </el-form>
    <div class="dialog-footer" slot="footer">
      <el-button class="cancel" @click="handleClose">取消</el-button>
      <el-button type="primary" @click="confirm">确定</el-button>
    </div>
  </el-dialog>
</template>

<script>
import base from "@/mixins/base";
import { printError } from "@/utils/util";
import {
  getAreaDown,
  findDownList,
  getListIdAndNames,
  createArea,
  getAreaDetail,
  updateArea
} from "@/api";
export default {
  name: "",
  components: {},
  data() {
    return {
      dialogLoading: false,
      employeeList: [],
      scheduleEmployeeList: [],
      areaList: [],
      areaForm: {
        areaName: "",
        parentId: "",
        scheduleEmployeeId: ""
      },
      list: [],
      WorkCompanyNoList: [], //负责主体
      areaFormRules: {
        areaName: [
          {
            required: true,
            message: "请输入大区名称",
            trigger: ["blur", "change"]
          }
        ],
        parentId: [
          {
            required: true,
            message: "请选择大区名称",
            trigger: ["blur", "change"]
          }
        ],
        scheduleEmployeeId: [
          {
            required: true,
            message: "请选择调度负责人",
            trigger: ["blur", "change"]
          }
        ]
      }
    };
  },
  props: ["areaId", "addAreaVisible", "addAreaType"],
  mixins: [base],
  computed: {},
  watch: {
    addAreaVisible: {
      immediate: true,
      handler(val) {
        if (val) {
          this.initData();
        }
      }
    }
  },
  methods: {
    async initData() {
      this.dialogLoading = true;
      try {
        let promiseList = [
          getAreaDown({}),
          getListIdAndNames({
            id: 1
          }),
          getListIdAndNames({
            id: 2
          }),
          findDownList(["WorkCompanyNo"])
        ];
        let [
          areaRes,
          employeeRes,
          scheduleEmployeeRes,
          eumsRes
        ] = await Promise.all(promiseList);
        //大区下拉框
        this.areaList = this.getFreezeResponse(areaRes, {
          path: "data"
        });
        //工程师下拉框
        this.employeeList = this.getFreezeResponse(employeeRes, {
          path: "data"
        });
        //调度员下拉框
        this.scheduleEmployeeList = this.getFreezeResponse(
          scheduleEmployeeRes,
          {
            path: "data"
          }
        );
        //负责主体下拉框
        let WorkCompanyNoList = eumsRes.data.WorkCompanyNo;
        WorkCompanyNoList.map(item => {
          item.disabled = false;
        });
        this.WorkCompanyNoList = WorkCompanyNoList;
        if (this.addAreaType == "add") {
          this.list.push({
            workEmployeeId: "",
            companyNos: ["1", "2", "3"],
            WorkCompanyNoList
          });
        }
        if (this.addAreaType == "edit") {
          this.getAreaDetail();
        }
      } catch (error) {
        printError(error);
      } finally {
        this.dialogLoading = false;
      }
    },
    //处理选项是否禁用
    handleDiabled() {
      let list = this.list;
      let ids = list.map(item => item.companyNos).flat();
      console.log(ids, list, "---");
      list.map(item => {
        let WorkCompanyNoList = item.WorkCompanyNoList;
        WorkCompanyNoList.map(v => {
          if (ids.indexOf(v.codeValue) != -1) {
            v.disabled = true;
          } else {
            v.disabled = false;
          }
        });
      });
      ids.map(item => {
        list.map(ele => {
          let obj = ele.WorkCompanyNoList.find(v => v.codeValue == item);
          if (ele.companyNos.indexOf(item) != -1) {
            obj.disabled = false;
          }
        });
      });
      this.list = list;
    },
    //点击添加工程师负责人
    addRow() {
      let WorkCompanyNoList = _.cloneDeep(this.WorkCompanyNoList);
      let list = this.list;
      list.push({
        workEmployeeId: "",
        companyNos: [],
        WorkCompanyNoList
      });
      this.list = list;
      this.handleDiabled();
    },
    //删除工程师负责人
    deleteRow(index) {
      this.list.splice(index, 1);
      this.handleDiabled();
    },
    changeCheckbox() {
      this.handleDiabled();
    },
    confirm() {
      this.$refs.areaForm.validate(valid => {
        if (valid) {
          if (this.list.some(item => !item.workEmployeeId)) {
            this.$message.warning("工程师负责人不能为空");
            return;
          }
          if (this.list.some(item => !item.companyNos.length)) {
            this.$message.warning("工程师负责主体不能为空");
            return;
          }
          if (this.addAreaType == "add") {
            this.createArea();
          } else {
            this.updateArea();
          }
        }
      });
    },
    //获取片区详情
    async getAreaDetail() {
      try {
        this.dialogLoading = true;
        let res = await getAreaDetail({ areaId: this.areaId });
        let areaForm = _.cloneDeep(res.data);
        this.areaForm = {
          areaId: areaForm.areaId ? areaForm.areaId : "",
          versionNumber: areaForm.versionNumber,
          areaName: areaForm.areaName ? areaForm.areaName : "",
          parentId: areaForm.regionalAreaId ? areaForm.regionalAreaId : "",
          scheduleEmployeeId: areaForm.scheduleEmployeeId
            ? areaForm.scheduleEmployeeId
            : ""
        };
        let list = areaForm.workEmployeeReqVOList;
        if (list.length) {
          list.map(item => {
            item.WorkCompanyNoList = _.cloneDeep(this.WorkCompanyNoList);
            item.companyNos = item.companyNos.split(",");
          });
          this.list = list;
          this.handleDiabled();
        } else {
          list.push({
            workEmployeeId: "",
            companyNos: ["1", "2", "3"],
            WorkCompanyNoList: this.WorkCompanyNoList
          });
          this.list = list;
        }
      } catch (error) {
        printError(error);
      } finally {
        this.dialogLoading = false;
      }
    },
    //创建大区请求
    async createArea() {
      try {
        this.dialogLoading = true;
        let workEmployeeReqVOList = this.list.map(item => {
          return Object.assign(
            {},
            {
              workEmployeeId: item.workEmployeeId,
              companyNos: item.companyNos.join(",")
            }
          );
        });
        let res = await createArea({
          ...this.areaForm,
          workEmployeeReqVOList
        });

        if (res.code == "OK") {
          this.$message.success("创建成功");
          this.$emit("refresh");
          this.handleClose();
        }
      } catch (error) {
        printError(error);
      } finally {
        this.dialogLoading = false;
      }
    },
    //编辑片区请求
    async updateArea() {
      try {
        this.dialogLoading = true;
        let workEmployeeReqVOList = this.list.map(item => {
          return Object.assign(
            {},
            {
              workEmployeeId: item.workEmployeeId,
              companyNos: item.companyNos.join(",")
            }
          );
        });
        let res = await updateArea({
          ...this.areaForm,
          workEmployeeReqVOList
        });
        if (res.code == "OK") {
          this.$message.success("编辑成功");
          this.$emit("refresh");
          this.handleClose();
        }
      } catch (error) {
        printError(error);
      } finally {
        this.dialogLoading = false;
      }
    },
    handleClose() {
      this.areaForm = {
        areaName: "",
        parentId: "",
        scheduleEmployeeId: "",
        workEmployeeId: ""
      };
      this.list = [];
      this.WorkCompanyNoList = [];
      this.$refs.areaForm.resetFields();
      this.$emit("handleClose", "addAreaVisible");
    }
  },
  created() {}
};
</script>
<style lang="scss" scoped>
/deep/.custom-dialog {
  .el-dialog__body {
    padding: 20px 18px 2px 18px;
    .el-input {
      width: 260px;
    }
    .content {
      .e6-icon-add_fill {
        margin-left: 10px;
        height: 28px;
        margin-right: 10px;
        color: #46bfea;
        cursor: pointer;
        font-size: 20px;
      }
      .e6-icon-minus_fill {
        margin-left: 10px;
        height: 28px;
        margin-right: 10px;
        color: #46bfea;
        cursor: pointer;
        font-size: 20px;
      }
    }
    .workEmployee {
      margin-bottom: 10px;
      // .el-form-item__label {
      //   float: none;
      // }
    }
  }
}
.tip {
  color: red;
  margin-bottom: 10px;
}
</style>

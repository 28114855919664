<!--
 * @Description: 添加大区弹框
 * @Author: ChenXueLin
 * @Date: 2021-09-10 15:20:48
 * @LastEditTime: 2022-07-01 09:27:18
 * @LastEditors: ChenXueLin
-->
<template>
  <el-dialog
    v-dialogDrag
    :title="addRegionalType == 'add' ? '添加大区' : '编辑大区'"
    :visible.sync="addRegionalVisible"
    width="500px"
    :before-close="handleClose"
    :close-on-click-modal="false"
    v-loading="dialogLoading"
    :element-loading-background="loadingBackground"
    custom-class="custom-dialog"
  >
    <el-form
      ref="regionalForm"
      label-width="88px"
      :model="regionalForm"
      :rules="regionalFormRules"
    >
      <el-form-item label="大区名称" prop="areaName">
        <el-input
          v-model.trim="regionalForm.areaName"
          placeholder="大区名称"
        ></el-input>
      </el-form-item>
      <el-form-item label="工程负责人" prop="workEmployeeId">
        <e6-vr-select
          v-model="regionalForm.workEmployeeId"
          :data="employeeList"
          placeholder="工程负责人"
          title="工程负责人"
          clearable
          :props="{
            id: 'employeeId',
            label: 'employeeName'
          }"
        ></e6-vr-select>
      </el-form-item>
      <el-form-item label="调度负责人" prop="scheduleEmployeeId">
        <e6-vr-select
          v-model="regionalForm.scheduleEmployeeId"
          :data="scheduleEmployeeList"
          placeholder="调度负责人"
          title="调度负责人"
          clearable
          :props="{
            id: 'employeeId',
            label: 'userName'
          }"
        ></e6-vr-select>
      </el-form-item>
    </el-form>
    <div class="dialog-footer" slot="footer">
      <el-button class="cancel" @click="handleClose">取消</el-button>
      <el-button type="primary" @click="confirm">确定</el-button>
    </div>
  </el-dialog>
</template>

<script>
import base from "@/mixins/base";
import { printError } from "@/utils/util";
import {
  getListIdAndNames,
  createRegional,
  getAreaDetail,
  updateRegional
} from "@/api";
export default {
  name: "",
  components: {},
  data() {
    return {
      dialogLoading: false,
      employeeList: [],
      scheduleEmployeeList: [],
      regionalForm: {
        areaName: "",
        scheduleEmployeeId: "",
        workEmployeeId: ""
      },
      regionalFormRules: {
        areaName: [
          {
            required: true,
            message: "请输入大区名称",
            trigger: ["blur", "change"]
          }
        ],
        scheduleEmployeeId: [
          {
            required: true,
            message: "请选择调度负责人",
            trigger: ["blur", "change"]
          }
        ],
        workEmployeeId: [
          {
            required: true,
            message: "请选择工程师",
            trigger: ["blur", "change"]
          }
        ]
      }
    };
  },
  props: ["areaId", "addRegionalVisible", "addRegionalType"],
  mixins: [base],
  computed: {},
  watch: {
    addRegionalVisible: {
      immediate: true,
      handler(val) {
        if (val) {
          this.initData();
        }
      }
    }
  },
  methods: {
    async initData() {
      this.dialogLoading = true;
      try {
        let promiseList = [
          getListIdAndNames({
            id: 1
          }),
          getListIdAndNames({
            id: 2
          })
        ];
        let [employeeRes, scheduleEmployeeRes] = await Promise.all(promiseList);
        //工程师下拉框
        this.employeeList = this.getFreezeResponse(employeeRes, {
          path: "data"
        });
        //调度员下拉框
        this.scheduleEmployeeList = this.getFreezeResponse(
          scheduleEmployeeRes,
          {
            path: "data"
          }
        );
        if (this.addRegionalType == "edit") {
          this.getAreaDetail();
        }
      } catch (error) {
        printError(error);
      } finally {
        this.dialogLoading = false;
      }
    },
    //获取大区详情
    async getAreaDetail() {
      try {
        let res = await getAreaDetail({ areaId: this.areaId });
        let areaForm = _.cloneDeep(res.data);
        this.regionalForm = {
          areaName: areaForm.areaName,
          scheduleEmployeeId: areaForm.scheduleEmployeeId,
          workEmployeeId: areaForm.workEmployeeId,
          areaId: areaForm.areaId,
          versionNumber: areaForm.versionNumber
        };
      } catch (error) {
        printError(error);
      }
    },
    confirm() {
      this.$refs.regionalForm.validate(valid => {
        if (valid) {
          if (this.addRegionalType == "add") {
            this.createRegional();
          } else {
            this.updateRegional();
          }
        }
      });
    },
    //创建大区请求
    async createRegional() {
      try {
        this.dialogLoading = true;
        let res = await createRegional(this.regionalForm);
        if (res.code == "OK") {
          this.$message.success("创建成功");
          this.$emit("refresh");
          this.handleClose();
        }
      } catch (error) {
        printError(error);
      } finally {
        this.dialogLoading = false;
      }
    },
    //编辑大区
    async updateRegional() {
      try {
        this.dialogLoading = true;
        let res = await updateRegional(this.regionalForm);
        if (res.code == "OK") {
          this.$message.success("编辑成功");
          this.$emit("refresh");
          this.handleClose();
        }
      } catch (error) {
        printError(error);
      } finally {
        this.dialogLoading = false;
      }
    },
    handleClose() {
      this.regionalForm = {
        areaName: "",
        scheduleEmployeeId: "",
        workEmployeeId: ""
      };
      this.$refs.regionalForm.resetFields();
      this.$emit("handleClose", "addRegionalVisible");
    }
  },
  created() {}
};
</script>
<style lang="scss" scoped>
/deep/.custom-dialog {
  .el-dialog__body {
    padding: 20px 18px 2px 18px;
    .el-input {
      width: 260px;
    }
  }
}
</style>
